.containerError, .containerSuccess {
	position:fixed;
	top:10px;
	left:50%;
	margin-left:-220px;
	width:440px;
	padding:20px;
	border-radius:4px;
	background:#c00;
	box-shadow:0 5px 15px rgba(37,48,62,.2);
	color:#fff;
	opacity:.01;
	visibility:visible;
	z-index:10004;
}
.containerSuccess {
	background:#498200;
}
.containerError, .containerSuccess {
	opacity:1;
	transition:opacity 300ms ease-in;
    animation:showHide 300ms ease-in 3s forwards;
    animation-fill-mode:forwards;
}
@keyframes showHide {
    to {
		opacity:.01;
    }
}