.loadingContainer {
	display:flex;
	flex:1;
}
.loadingContainer div {
	margin:auto;
	color:#777;
	font-size:14px;
}
.loadingContainer div img {
	display:block;
	margin:0 auto 20px;
}