h2 {
	margin:20px 0 50px 0 !important;
	line-height:1.4 !important;
}
h3.savingtitle {margin-bottom:10px;}
.block {
	display:block;
	padding:15px;
	margin-bottom:20px;
	border:1px solid #ddd;
	border-radius:10px;
	background:#fff;
	box-shadow:0 2px 4px rgba(0,0,0,.2);
	color:#000;
	text-decoration:none;
}
.column {
	display:flex;
	flex-direction:row;
	margin:5px 0;
}
.column .row {justify-content:space-between;}
.column .row b {display:block;}