.btn, .btnouter {
	width:100%;
	padding:15px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
}
.btnouter {
	border:1px solid #ccc;
    background:#f8f8f8;
	color:#555;
	box-shadow:0 2px 1px 0 rgba(0,0,0,.05);
	cursor:pointer;
}
.btn:hover {
	cursor:pointer;
	opacity:.8;
}
.ctrl, .ctrllast {
	display:flex;
	align-items:center;
	margin:10px 0;
}
.ctrllast {
	margin-bottom:60px;
}
h2 {
	margin:10px 0 50px 0 !important;
	line-height:1.4 !important;
}