h2 {
	margin:20px 0 50px 0 !important;
	line-height:1.4 !important;
}
.column {margin:5px 0;}
.column .row {width:100%;}
.column .row b {display:block;}
.column .row input {
	margin:5px 0 20px;
	padding:10px;
	border-radius:4px;
	border:1px solid #ccc;
}
.ctrl {
	display:flex;
	flex-direction:row;
	margin:20px 0;
	justify-content:space-between;
}
.ctrl a {
	display:block;
}
.btn {
	display:block;
	width:100%;
	padding:10px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	text-align:center;
}
.btn:hover {
	cursor:pointer;
	opacity:.8;
}