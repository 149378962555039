.btn {
	width:100%;
	padding:15px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
}
.btn:hover {
	cursor:pointer;
	opacity:.8;
}
.ctrl {
	display:flex;
	align-items:center;
	margin:20px 0;
}
.logo {
	width:200px;
	display:flex;
	margin:20px auto 50px;
}