h2 {
	margin:20px 0 50px 0 !important;
	line-height:1.4 !important;
}
h3.savingtitle {margin-bottom:10px;}
.block {
	padding:15px;
	margin-bottom:20px;
	border:1px solid #ddd;
	border-radius:10px;
	background:'#fff';
	box-shadow:0 2px 4px rgba(0,0,0,.2);
}
.btn, .btnouter {
	display:block;
	width:100%;
	padding:10px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	text-align:center;
}
.btnouter {
	border:1px solid #ccc;
    background:#f8f8f8;
	color:#555;
	box-shadow:0 2px 1px 0 rgba(0,0,0,.05);
	cursor:pointer;
}
.btn:hover {
	cursor:pointer;
	opacity:.8;
}
.column {
	display:flex;
	flex-direction:row;
	margin:5px 0;
}
.column .row {
	width:50%;
	justify-content:space-between;
}
.column .row b {display:block;}
.ctrl {
	display:flex;
	flex-direction:row;
	margin:20px 0;
	justify-content:space-between;
}
.ctrl a {
	display:block;
	width:49%;
}