h2 {
	margin:20px 0 50px 0 !important;
	line-height:1.4 !important;
}
.column {margin:5px 0;}
.column .row {
	width:100%;
	margin-bottom:20px;
}
.column .row b {display:block;}
.column .row input {
	margin:5px 0;
	padding:10px;
	border-radius:4px;
	border:1px solid #ccc;
}
.ctrl {
	display:flex;
	flex-direction:row;
	margin:20px 0;
	justify-content:space-between;
}
.ctrl a {
	display:block;
}
.btn {
	display:block;
	width:100%;
	padding:10px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
	text-align:center;
	margin-top:20px;
}
.btn:hover {
	cursor:pointer;
	opacity:.8;
}
.formColumn {
	display:block;
	margin:20px 0 40px;
}
.formColumn label {
	margin:20px 0;
	position:relative;
}
.formColumn label:hover .inputPanel {
	cursor:pointer;
	border-color:#999;
}
.formColumn label span.placeholder {
	margin-left:20px;
	position:absolute;
	left:0;
	top:-11px;
	transition:.2s;
	transition-timing-function:ease;
	transition-timing-function:cubic-bezier(.25, .1, .25, 1);
	opacity:.4;
	color:#000;
	cursor:pointer;
	white-space:nowrap;
}
.formColumn .inputPanel {
	padding:30px 50px 10px 20px;
	width:100%;
	border-radius:4px;
	border:1px solid #ccc;
	font-size:110%;
}
.formColumn .inputPanel:focus,
.formColumn label:hover .inputPanel:focus {
	border:2px solid #21add9;
	padding:29px 49px 9px 19px;
}
.formColumn .inputPanel:focus+span.placeholder,
.formColumn .inputPanel:not(:placeholder-shown)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.formColumn .inputPanel:focus+span.placeholder,
.formColumn .inputPanel:not(:-ms-input-placeholder)+span.placeholder {
	opacity:1;
	color:#777;
	transform:translateY(-10px) translateX(0);
	font-size:80%;
}
.formColumn .hintError {
	display:block;
	font-size:85%;
	margin-top:5px;
	text-align:left;
	margin-left:5px;
	position:absolute;
	bottom:-35px;
	color:#999;
}
.formColumn .inputPanel[iserror=true],
.formColumn .inputPanel[iserror=true]:focus,
.formColumn label:hover .input-panel[iserror=true] {
	border-color:#ff402b;
	background-color:#fff9f8;
	background:url('../../Images/icons/attention.svg') 100% 9px no-repeat;
}
.formColumn .inputPanel[issuccess=true],
.formColumn .inputPanel[issuccess=true]:focus {
	background:url('../../Images/icons/success.svg') 100% 9px no-repeat;
}
.formColumn .inputPanel[iserror=true]~.hint-error {
	color:#ff402b;
}
button.btn {
	width:100%;
	padding:15px 0;
	border:none;
    border-radius:6px;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#f3ae56;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
}
button.btn:hover {
	cursor:pointer;
	opacity:.8;
}