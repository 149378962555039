.errorinfo a {
	color:#2475e6;
	text-decoration:none;
	padding-bottom:3px;
	border-bottom:1px solid rgba(36,117,230,.4);
}
.logo {
	width:200px;
	display:flex;
	margin:20px auto 50px;
}