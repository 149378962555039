.confirmcontainer {
	padding:20px;
}
.confirmcontainer h4 {
	margin:0 0 20px 0 !important;
	font-weight:normal;
}

.confirmcontainer .buttons {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	margin-top:30px;
}
.confirmcontainer .buttons button {
	border:none;
    background:#ea6060;
    color:#fff;
	padding:8px 16px;
	margin-right:30px;
    border-radius:4px;
}
.confirmcontainer .buttons button, .confirmcontainer .buttons a {
	cursor:pointer;
}
.confirmcontainer .buttons a {
	color:#555;
}