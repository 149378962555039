h2 {
	margin:20px 0 50px 0 !important;
	line-height:1.4 !important;
}
table {
	width:100%;
}
table thead tr {
	background:#07385a;
	color:#fff;
	text-align:left;
}
table th, table td {
	padding:4px 8px;
}
table tbody tr:nth-child(even) {
	background:#eee;
}
table td.nowrap {
	white-space:nowrap;
}