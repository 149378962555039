html,body,ul,ol,li,dl,dt,dd,p,pre,h1,h2,h3,h4,h5,h6,blockquote,form,fieldset,legend,table,td,th {margin:0;padding:0;}
html,body {width:100%;height:100%}
body {overflow-x:hidden;min-height:100%;background:#fff;color:#000;font:14px/1.5 Arial,'Helvetica Neue',Helvetica,sans-serif;display:flex;flex-direction:column;height:100vh;}
html {scroll-behavior:smooth;}

img {margin:0;border-width:0;padding:0;}
ul {list-style:none;}

* {box-sizing:border-box;outline:none;}

h1,h2,h3,h4 {letter-spacing:-.03em;line-height:1.032em;font-weight:700;}
h1 {font-size:36px;}
h2 {font-size:30px;font-weight:700;}
h3 {font-size:24px;}
h4 {font-size:18px;}

#root {display:flex;flex-direction:column;height:100%;}

::-webkit-scrollbar {
	background:transparent;
	width:.5em;
	position:absolute;
}
::-webkit-scrollbar-track {
	background:transparent;
	position:absolute;
	z-index:-2;
}
::-webkit-scrollbar-thumb {
	border-radius:100px;
	background:#888;
}

/* general */

.container {
	position:relative;
	width:440px;
	margin:100px auto 0;
	padding:30px 50px;
	background:#FFF;
	border-radius:5px;
	box-shadow:0 5px 15px rgba(37, 48, 62, .2);
}
.container p {
	margin:20px 0;
	font-size:16px;
}

/* mobile */

@media (min-width: 320px) and (max-width: 480px) {
	.container {
		width:auto;
		margin-top:20px;
		box-shadow:none;
		padding:30px 30px 0;
		margin:20px 0 0;
	}
	.back {
		position:fixed;
		left:20px;
		top:20px;
	}
}